<script setup lang="ts">
const props = defineProps({
    className: {
    type: String,
  }, 
  id: {
    type: String
  }
})

const classes = `${props.className} w-full flex justify-center px-6 md:px-24p lg:px-64p xl:px-100p`
</script>
<template>
    <section :id="id" :class="classes">
        <div class="w-full lg:max-w-xxl-desktop">
        <slot></slot>
        </div>
    </section>
</template>